.cageFrame {
  height: 48px;
  background: rgba(213, 234, 255, 0.5);
  border-radius: 4px;
  padding: 0 20px;

  .tit {
    color: #213333;
    width: 100px;

    img {
      margin-right: 5px;
    }
  }
}

.tabs ::v-deep {
  margin-left: 30px;
  width: 80%;

  .el-tabs__header {
    margin-bottom: 0;
  }

  .el-tabs__active-bar {
    height: 0;
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-tabs__item {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-left: 25px;

    &.is-active {
      color: #10B8F1;
    }
  }
}

.cuddlesList {
  padding: 8px 0;
  margin-top: 20px;
  height: 90%;
  overflow-y: scroll;
  align-items: normal;
  flex-wrap: wrap;
  .list {
    width: 146px;
    height: 146px;
    border: 1px solid #4896FF;
    border-radius: 4px;
    justify-content: center;
    margin-right: 24px;
    flex-flow: column;
    cursor: pointer;
    color: #333333;

    .commonBg {
      width: 60px;
      height: 60px;

      justify-content: center;
      color: #FFFFFF;
      align-items: flex-end;
      padding-bottom: 12px;
    }

    .frameNo {
      background: url("~@/assets/img/cageBg.png") no-repeat center;
      background-size: 100%;
    }

    .freeBg {
      background: url("~@/assets/img/free.png") no-repeat center;
      background-size: 100%;
    }

    .headUser {
      width: 124px;
      height: 32px;
      background: #D5EAFF;
      border-radius: 0 0 4px 4px;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      color: #006DFF;
      margin-top: 15px;

      img {
        margin-left: 5px;
      }
    }

    .free {
      width: 124px;
      height: 32px;
      background: rgba(36, 40, 50, 0.1);
      border-radius: 0 0 4px 4px;
      line-height: 32px;
      font-size: 14px;
      color: #BBBBBB;
      text-align: center;
      margin-top: 15px;
    }

    &.unUse {
      border-color: #B2BBC4;

      h4 {
        color: #BBBBBB;
      }
    }
  }
}

.common {
  background: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;
  float: left;
  height: calc(100% - 51px);
}

.leftVideo {
  width: 530px;
}


.info {
  line-height: 2;
  font-size: 14px;
  font-weight: 400;
  color: #333333;

  span {
    display: inline-block;
    width: 50%;
  }

  .number {
    font-size: 16px;
    font-weight: bold;
    color: #006DFF;
  }

  .colorStyle {
    color: #006DFF;
  }
}

.rightInfo {
  line-height: 2;
  margin-left: 24px;
  width: calc(100% - 554px);

  .tit {
    font-size: 16px;
    font-weight: bold;
    color: #006DFF;
    border-bottom: 1px solid rgba(130, 182, 253, 0.5);
    padding: 0 8px 10px;
  }
}

.maxHeightContent {
  max-height: 92%;
  overflow-y: scroll;
  margin: 16px 0 0;
  padding: 0 8px;

  .mt-10 {
    margin-bottom: 10px;

    span {
      display: inline-block;
      width: 50%;
    }
  }
}
